import React, { useMemo } from "react";
import { Select } from "../../../helpers";
import { states } from "../../../../geo";
import { FilterMainComponentProps } from "../Filter";

const ProvinceSelect = ({
  values,
  onChange,
  dependsOnValues = [],
  placeholder = "All",
}: FilterMainComponentProps) => {
  const provinceOptions = useMemo(() => {
    if (dependsOnValues[0] in states) {
      return [
        { value: "", label: "All" },
        ...states[dependsOnValues[0]].map(
          (item: { name: string; abbr: string }) => ({
            value: item.abbr,
            label: item.name,
          }),
        ),
      ];
    } else {
      return [{ value: "", label: "All" }];
    }
  }, [dependsOnValues[0]]);

  return (
    <Select
      value={values}
      // @ts-ignore
      onChange={(e: { value: string; label: string }) =>
        onChange(e?.value || null)
      }
      isClearable={values !== ""}
      options={provinceOptions}
      placeholder={placeholder}
    />
  );
};

export default ProvinceSelect;
